import React, { useEffect } from "react"
import { _, session, store, Emitter } from "../store/psrs"
import useMyContext from "../store/useMyContext"
import queryString from "query-string"
import "dhtmlx"
import "dhtmlx/ext/dhtmlxscheduler_active_links"
import "dhtmlx/ext/dhtmlxscheduler_agenda_view"
import "dhtmlx/ext/dhtmlxscheduler_grid_view"
import "dhtmlx/ext/dhtmlxscheduler_timeline"
import "dhtmlx/ext/dhtmlxscheduler_treetimeline"
import "dhtmlx/ext/dhtmlxscheduler_daytimeline"

import "dhtmlx/ext/dhtmlxscheduler_minical"
import "dhtmlx/ext/dhtmlxscheduler_tooltip"

import PayPeriodNav from "./PowerView/PayPeriodNav"
import moment from "moment-timezone"
import { useStateIfMounted } from "use-state-if-mounted"
import ShiftDrawer from "./ShiftDrawer"
// eslint-disable-next-line 
import { Row, Col, Button, Tooltip, Popover, message, notification } from "antd"
import { TagSelect, SupervisorSelect } from "./Forms"
import { REQ } from "../store/psrs"


//import "../../node_modules/dhtmlx/ext/dhtmlxscheduler_minical"
//import "../../node_modules/dhtmlx/ext/dhtmlxscheduler_tooltip"

const scheduler = window.scheduler

export const ScheduleTimeline = props => {
  const context = useMyContext()
  const currentUser = session.get("CurrentUser")
  const employeeNumber = currentUser.number

  const [secondScale, setSecondScale] = useStateIfMounted({
    x_unit:  "day", // unit which should be used for second scale
    x_date: "%D %M %d" // date format which should be used for second scale, "July 01"
  })

  const [shiftUpdated, setShiftUpdated] = useStateIfMounted(false)
  const { shiftDrawerId } = context
  const [params, setParams] = useStateIfMounted({
    tagIds: [],
    supervisorNumber: currentUser.role === "supervisor" ? employeeNumber : currentUser.supervisorNumber,
    payPeriodId: store.get("PayPeriod").id,
    payPeriodStartId: store.get("PayPeriod").id,
    payPeriodEndId: store.get("PayPeriod").id,
    payPeriod: store.get("PayPeriod"),
  })
  const [shifts, setShifts] = useStateIfMounted([])
  const [state, setState] = useStateIfMounted({
    active: null,
    days: 1,
    event: false,
    loading: false,
    mounted: false,
    scale: 1, //50,
    step: 1,
    payPeriod: store.get("PayPeriod"),
    timeline: scheduler.matrix.timeline,
    supervisor: _.find(store.get("Supervisors"), { number: params.supervisorNumber }),
    currentPayPeriod: false, 
    allYear: false,
    currentPP: store.get("PayPeriodToday"),
    selectedPP: store.get("PayPeriod"),
    currentViewChanged: 0,

  })

  const setTemplates = () => {
    //console.log("setTemplates")

    scheduler.templates.event_class = function (start, end, evt) {
      let classes = [`scale_${state.scale}`]
      if (evt.approved) classes.push("approved")
      if (evt.worked) classes.push("worked")
      if (evt.isSick) classes.push("sick")
      if (evt.requestedOff ) classes.push("pto")
      if (evt.isOT) classes.push('ot')
      if (evt.id === state.shiftId) classes.push("lift")
      if (evt.isShortShift && evt.shortShiftEarly) classes.push("short_early")
      if (evt.isShortShift && evt.shortShiftLate) classes.push("short_late")
      return classes.join(" ")
    }

    scheduler.templates.tooltip_text = function(start,end,event) {
      const shift = event
      const type =  shift.requestedOff ? 'LEAVE' : shift.isSick ? 'SICK' : shift.isOT ? 'OT' :'Regular'
      const notes = shift.notes ? shift.notes : ""
      let short = ''
      const shortShift= shift.isShortShift && shift.shortShiftEarly ? 'Short Early' : shift.isShortShift && shift.shortShiftLate ? 'Short Late' :''
      if (shortShift) {
        short += `<br/><b>Short:</b> ${shortShift}`
      }

      let assignment = shift.assignmentId && shift.assignment ? shift.assignment.name : ""
      if (assignment) {
        assignment = `<br /><b>Assignment:</b> ${assignment}`
      }
      

      return "<b>Employee:</b> "+shift.text+"<br/><b>Start date:</b> "+
      moment(shift.start_date).format('ddd MMM D @ HH:mm')+"<br/><b>End date:</b> "+
      moment(shift.end_date).format('ddd MMM D @ HH:mm')+"<br/><b>Duration:</b> "+
      shift.duration+"<br/><b>Type:</b> "+
      type + short+assignment+
      `<br/><span style="width:200px; word-wr ap:break-word; display:inline-block;"><br /><b>Notes:</b> `+
      notes+`</span>`;
    };

    scheduler.templates.event_bar_text = function (start, end, evnt) {
      const shift = evnt
      const { employee } = shift
      //const Supervisor = shift.supervisor
      const assignment = shift.assignmentId ? shift.assignment : false
      const businessUnit = shift.costCenterChargeOutCode ? 
           shift.costCenterChargeOutCode + '<br />'+shift.costCenterChargeOut.name 
           :
           shift.businessUnitCode + '<br />'+shift.businessUnit.name

      if (employee && shift) {
        const lastName = employee.lastName
        const firstInitial = `${employee.firstName[0]}.`
        
        if (scheduler.matrix.timeline.x_step === 1) {
               
          return `
              <div class="shift_bar">
                  <div class="left_meta">
                    <span>
                      ${shift.requestedOff ? `<span class="pto">LEAVE</span>` : ``}
                      ${shift.isOT ? `<span class="ot">OVERTIME</span>` : ``}
                      ${shift.isSick ? `<span class="sick">SICK</span>` : ``} 
                      ${shift.isShortShift && shift.shortShiftEarly ? `<span class="sick">Short Early</span>` : ``} 
                      ${shift.isShortShift && shift.shortShiftLate ? `<span class="sick">Short Late</span>` : ``} 
                    </span>
                  </div>
                  <span class="name">
                    <span class="first">${firstInitial} </span>
                    <span class="last">${lastName}</span>
                  </span>
                  <span class="job_title">
                    ${employee.jobTitle}&nbsp;&nbsp;<br />
                    ${assignment ? `<span class="assignment">${assignment.name}</span>` : ``} 
                   </span>
                   <span>
                    <span class="bu_code">${businessUnit}</span>
                   </span>
                  <span>
                    ${shift.name ? `<br/><div class="shift_name">${shift.name}</div>` : ``}
                   
                  </span>
                  <div class="right_meta">
                    <span class="duration">${shift.duration} <b>hrs</b></span>
                  </div>
                </div>
              `
        } else {
          
          return `
         
          <div class="shift_bar">  
            <div class="right_meta">
              <span class="duration">${shift.duration}</span>
              <span class="last last-truncate">${lastName}</span>
            </div>
          </div>
          `
        }
        
      } else {
        return "AHOY ... this is a new event"
      }
    }
    scheduler.templates.timeline_scale_date = function (date) {
      let theDate = moment(date).format("HH:mm")
      return theDate
    }
    scheduler.locale.labels.timeline_scale_header = ""
    // setTimeout(() => scheduler.updateView(), 1000)
  }

  const toggleLoading = (loading, period) => {
    setState({ ...state, loading: loading ? loading : !state.loading })
    if (period) setParams({ ...params, payPeriod: period })
  }

  const selectSupervisor = ({ value }) => {
    //console.log("selectSupervisor", value)
    const supervisorNumber = value //? value : state.supervisor.number
    setState({ ...state, supervisor: _.find(store.get("Supervisors"), { number: supervisorNumber }) })
    setParams({ ...params, supervisorNumber })
  }

  const selectGroup = async (args) => {
    params.tagIds = []
      await args.value.forEach( val => {
      if (!params.tagIds.includes(val)) {
        params.tagIds.push(val)
      }
    }) 
    setParams({...params, tagIds: params.tagIds})
  }

  const customSort = (a, b) => {
  
    const regex = /(\D*)(\d*)/;
    const [, textA, numberA] = a.tags.length === 0 ? "" : a.tags[0].name.match(regex);
    const [, textB, numberB] = b.tags.length === 0 ? "" : b.tags[0].name.match(regex);
  
    // Compare the alphabetic parts
    if (textA < textB) return -1;
    if (textA > textB) return 1;
  
    // If alphabetic parts are equal, compare the numeric parts (if they exist)
    if (numberA === '' && numberB === '') return 0;
    if (numberA === '') return -1;
    if (numberB === '') return 1;
  
    return parseInt(numberA, 10) - parseInt(numberB, 10);
    
  }

  const setInLocalStorage = (keyName, value) => {
    try {
        store.set(keyName, value);
    } catch (error) {
        console.log('Error in local storage', error);
        setInLocalStorage(keyName,store.get(keyName).slice(0, 100));
    }
  }

  const fetchData = async () => {
    //setShiftUpdated({...state})
    const query = {}
    if (params.tagIds.length) query.tagIds = params.tagIds
    if (params.supervisorNumber) query.supervisorNumber = params.supervisorNumber

    // check for filters
    if (params.tagIds.length === 0 && !params.supervisorNumber && state.scale === 28) {
      const key = `open${Date.now()}`
      
      notification.warning({
        message: "Please filter by Group and/or Supervisor",
        description: `This would fetch too many records` ,
        placement: "topRight",
        duration: 4,
        key,
        btn: (
          <Button type="primary" size="small" onClick={() => {
            
            const supervisorNumber = currentUser.role === "supervisor" ? employeeNumber : currentUser.supervisorNumber
            setState({ ...state,scale: 1, supervisor: _.find(store.get("Supervisors"), { number: supervisorNumber }) })
            setParams({ ...params, supervisorNumber })

            notification.close(key)
            }
          } >
            Confirm
          </Button>
        )
      })
      return 
    }


    if (state.scale === 1 || state.scale === 14) {
      if (params.payPeriodId) query.payPeriodId = params.payPeriodId
      const ppToday = store.get("PayPeriod").id
      if (ppToday) query.payPeriodId = ppToday
    } else {
      query.payPeriodId = [params.payPeriodId, store.get("PayPeriodNext").id]
      
    }
    const q = queryString.stringify(query)
    let fetched = await REQ(`/api/shifts/schedule?${q}`)
    if (fetched && !!fetched.length) {
      setInLocalStorage("Shifts", fetched)
       // store.set("Shifts", fetched)      
      setState({ ...state, loading: false, mounted: true })
      //console.log("fetchData", fetched)
      const _shifts = fetched.filter(shift => !!shift && !!shift.employee && !!shift.supervisor1 && shift.end && shift.start)
      
      //var sortedShifts = _.sortBy( _shifts, function(o) {
      //  return moment(o.start)
      //},['desc']);

      const Shifts = _shifts.map(shift => {
        return {
          costCenterChargeOutCode: shift.costCenterChargeOutCode,
          costCenterChargeOut: shift.costCenterChargeOut,
          duration: shift.duration,
          durationOg: shift.durationOg,
          durationOt: shift.durationOt,
          text: shift.employee.fullName,
          id: shift.i,
          shiftId: shift.id,
          assignmentId: shift.assignmentId,
          assignment: shift.assignment,
          employeeNumber: shift.employeeNumber,
          employee: shift.employee,
          supervisor: shift.supervisor1,
          superNumber1: shift.supervisor1.number,
          businessUnitCode: shift.businessUnitCode,
          businessUnit: shift.businessUnit,
          shifts: 1,
          start_date: `${moment(shift.start).format("MM-DD-YYYY HH:mm")}`,
          end_date: `${moment(shift.end).format("MM-DD-YYYY HH:mm")}`,
          approved: shift.approved,
          worked: shift.worked,
          isSick: shift.isSick,
          requestedOff: shift.requestedOff,
          isOT: shift.isOT,
          isShortShift: shift.isShortShift,
          shortShiftEarly: shift.shortShiftEarly,
          shortShiftLate: shift.shortShiftLate,
          fetched: false,
          tags: shift.tags,
          tagId: shift.tags.length >0  ? shift.tags[0].id : '',
          // current -- sectionId: shift.tags.length > 0 ? shift.tags[0].id+shift.supervisor1.number : shift.supervisor1.number,
          sectionId: shift.tags.length > 0 ? shift.tags[0].id : shift.supervisor1.number,
          notes: shift.notes //.replace(/^(.{80}).{2,}/, "$1…")
        }
      })

      let allShifts
      if (shifts[0]) {
        allShifts = [...shifts, ...Shifts]
      } else { 
         allShifts = Shifts
      }
      //allShifts.push(Shifts)

      await loadData(allShifts)
      //setShifts(allShifts)
      setShifts(Shifts)
     
      //const mode = scheduler.getState().mode
      //if (mode === 'timeline') { 
      //  scheduler.matrix.timeline.scrollTo(sortedShifts.length > 0 ? new Date(sortedShifts[0].start) : new Date())
      //  scheduler.updateView()
      //  scheduler.openAllSections()
     // }

    }
  }

  const handleClick = i => {
    // this is totally absurd
    const Shifts = store.get("Shifts")
    const Shift = Shifts.find(s => s.i === parseInt(i))
    context.setShiftDrawerId(Shift.id)
  }

// eslint-disable-next-line
  const toNearest30Minutes = (date) => {
    const start = moment(date)
    let remainder //: number
    const elapse = start.minute() % 30
    if (elapse === 0) {
      return moment(date).format()
    } else {
      remainder = 30 - elapse
      return moment(start).add(remainder, "minutes").utc().format()
    }
  }

  // eslint-disable-next-line
  const toNearest15Minutes = (date) => {
    const start = moment(date)
    let remainder  //: number
    const elapse = start.minute() % 15
    if (elapse === 0) {
      return moment(date).format()
    } else {
      remainder = 15 - elapse
      return moment(start).add(remainder, "minutes").utc().format()
    }
  }

// eslint-disable-next-line
  const roundDate = (date, duration, method) => {
    return moment(Math[method]((+date) / (+duration)) * (+duration))
}

// eslint-disable-next-line
  const updateShift = async ({ shiftId, field, value }) => {
		
    //"2021-11-16T00:00:00.000Z"
    if (!shiftUpdated) {
      const update = field ? {} : value
      if (field) {
        update[field] = value
      }
      //shiftUpdates.push(update)
      
      console.log(update)

      const updated = await REQ(`/api/shifts/${shiftId}`, "PATCH", update)
    
      console.log(updated)

      //if (updated.isOT || updated.isSick || update.requestedOff) {
        //requestApproval = true
      //} else {
        //requestApproval = false
      //}
      //mutate(url, updated)

      //await loadData()
      setShiftUpdated(true)
    }
  }

  const showMinical = () => {

    if (scheduler.isCalendarVisible()){
       scheduler.destroyCalendar();
    } else {
        scheduler.config.minicalendar.mark_events=true
        scheduler.renderCalendar({
            position:"dhx_minical_icon",
            date:scheduler._date,
            navigation:true,
            handler:function(date,calendar){
                scheduler.setCurrentView(date);
                scheduler.destroyCalendar()
            }
        });
    }
  }
  const attachEvents = () => {
    //console.log("attachEvents")
    for (let id of context.dhtmlxAttachedEventIds) {
      scheduler.detachEvent(id)
    }
    let onClearAll = scheduler.attachEvent("onClearAll", async shifts => {})
  
    let onEventChanged = scheduler.attachEvent("onEventChanged", (id, ev) => {
      
      console.log('onEventChange')
      
      
      /*
      let event = scheduler.getEvent(id)
      let start_date = scheduler.getUserData(id, "start_date")
      let end_date = scheduler.getUserData(id, "end_date")

      
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

      const _start_date = toNearest15Minutes(start_date)
      const _end_date = toNearest15Minutes(end_date)

      scheduler.setUserData(id,"end_date", end_date)
      */
      return true

    })

    let onBeforeEventChanged = scheduler.attachEvent("onBeforeEventChanged", (ev, e, is_new, original) => {
      console.log('onBeforeEventChanged')

      //const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

      //const change = ev
      //const checkStart = moment(change.start_date)   //.tz(userTimeZone)
      //const checkEnd = moment(change.end_date)   //.tz(userTimeZone)
      //const originalStart = moment(original.start_date)   //.tz(userTimeZone)
      //const originalEnd = moment(original.end_date)   //.tz(userTimeZone)

      // original duration
      //const _start = moment(original.start_date)
      //const _end = moment(original.end_date)
      //const durationOg = Math.round((original.durationOg + Number.EPSILON) * 100) / 100
      //const duration = Math.round((_end.diff(_start, 'hours', true)+ Number.EPSILON) * 100) / 100
      //const durationOt = Math.round((_.clamp(duration - durationOg, 0, 20)+Number.EPSILON) * 100) / 100
      //const isOT = durationOt > 0

      // new duraiton
      //const _cstart = moment(change.start_date)
      //const _cend = moment(change.end_date)
      //const cdurationOg = Math.round((change.durationOg + Number.EPSILON) * 100) / 100
      //const cduration = Math.round((_cend.diff(_cstart, 'hours', true)+ Number.EPSILON) * 100) / 100
      //const cdurationOt = Math.round((_.clamp(cduration - cdurationOg, 0, 20)+Number.EPSILON) * 100) / 100
      //const cisOT = cdurationOt > 0

      //shift.start = _start.toDate()
      //shift.end = _end.toDate()
      //shift.duration = duration
      //shift.durationOt = durationOt
      //shift.isOT = isOT
      
      //if (!_start.isSame(_cstart) && cduration !== duration) {
      // const roundedStartDate = toNearest15Minutes(_cstart)
       //if (!shiftUpdated) updateShift({ shiftId: change.shiftId, field: 'start', value: roundedStartDate }) 
     // }
      //if (!_end.isSame(_cend) && cduration !== duration) {
      //  const roundedEndDate = toNearest15Minutes(_cend)
        //if (!shiftUpdated) updateShift({ shiftId: change.shiftId, field: 'end', value: roundedEndDate }) 
      //} 
      
      //if (cduration === duration) {
      //  const roundedStartDate =  toNearest15Minutes(_cstart)
      //  const roundedEndDate =  toNearest15Minutes(_cend)
      //  //if (!shiftUpdated) updateShift({ shiftId: change.shiftId, field: 'start', value: roundedStartDate }) 
      //  //if (!shiftUpdated) updateShift({ shiftId: change.shiftId, field: 'end', value: roundedEndDate }) 
      //} 
      
      //else {
      //  onst roundedStartDate = toNearest15Minutes(_cstart)
      //  const roundedEndDate = toNearest15Minutes(_cend)
      //  updateShift({ shiftId: change.shiftId, field: 'start', value: _cstart }) 
      //  updateShift({ shiftId: change.shiftId, field: 'end', value: _cend }) 
      //}

      //loadData()

      return  true
    })
   
    let onBeforeDrag = scheduler.attachEvent("onBeforeDrag", (id, mode, e) => {
      //console.log('onBeforeDrag')
      return  true
    })
    let onEventDrag = scheduler.attachEvent("onEventDrag", (id, mode, e) => {
      console.log('onEventDrag : mode ->', mode)
      console.log('onEventDrag : id   -> ', id)
      //console.log('onEventDrag : e    -> ', e)
      let event = scheduler.getEvent(id)
      console.log('onEventDrag : Updated event times -> ', event)

      let start_date = scheduler.getUserData(id, "start_date")
      let end_date = scheduler.getUserData(id, "end_date")

      console.log('onEventDrag : original start_date -> ', start_date)
      console.log('onEventDrag : original start_date -> ', end_date)

       return true 
    } )

    let onBeforeViewChange = scheduler.attachEvent("onBeforeViewChange", (old_mode,old_date,mode,date) => {
      console.log('onBeforeViewChange -> ')
      return true
    })
    
    let onEmptyClick = scheduler.attachEvent("onEmptyClick", (date, e) => false)
    let onDblClick = scheduler.attachEvent("onDblClick", (id, e) => false)
    let onCellDblClick = scheduler.attachEvent("onCellDblClick", (x_ind, y_ind, x_val, y_val, e) => false)
    let onXScaleClick = scheduler.attachEvent("onXScaleClick", () => false)
    let onYScaleClick = scheduler.attachEvent("onYScaleClick", () => false)
    let onSchedulerResize = scheduler.attachEvent("onSchedulerResize", () => false)
    let onClick = scheduler.attachEvent("onClick", handleClick)
    const attachedEvents = [
      onClearAll,
      onEventChanged,
      onBeforeEventChanged,
      onBeforeDrag,
      onEventDrag,
      onEmptyClick,
      onDblClick,
      onCellDblClick,
      onXScaleClick,
      onYScaleClick,
      onSchedulerResize,
      onClick,
      onBeforeViewChange,
    ]
    context.dhtmlxSetAttachedEventIds(attachedEvents)
  }

  const isDateInRange = async (selectedDate, range) => {
    const selected = new Date(selectedDate);

    const realRange = store.get("PayPeriod").range
   
    const start = new Date(realRange[0].value);
    const end = new Date(realRange[1].value);
  
    const isAfterStart = realRange[0].inclusive ? selected >= start : selected > start;
    const isBeforeEnd = realRange[1].inclusive ? selected <= end : selected < end;
  
    return isAfterStart && isBeforeEnd;
  }

  const handleClear = () => {
      scheduler.clearAll()
      //scheduler.deleteAllSections() 
      setShifts([])
  }

  const resetPayPeriod = async () => {
    const currentPP = store.get("PayPeriodToday")
    store.set("PayPeriod", currentPP)
    store.set("PayPeriodNext", context.getRecord("PayPeriods", currentPP.nextId))
    store.set("PayPeriodPrev", context.getRecord("PayPeriods", currentPP.prevId))
    store.set("Today", true)
    
    setParams({...params, payPeriod: currentPP, payPeriodId: currentPP.id } )
    //fetchData()

    // reset buttons on params change
    const prevButton = document.querySelector('.dhx_cal_prev_button')
    const nextButton = document.querySelector('.dhx_cal_next_button')
    const todayButton = document.querySelector('.dhx_cal_today_button')

    if (store.get('Today') === true) {
       todayButton.style.backgroundColor = '#1D90FF'
       todayButton.style.color = 'white'
    } else {
      todayButton.style.backgroundColor = ''
      todayButton.style.color = 'black'
    }
   

    if (state.scale === 14 || state.scale === 28) { 
      nextButton.disabled = true
      nextButton.style.display = 'none'
      prevButton.disabled = true
      prevButton.style.display = 'none'

    } else {
      
      const currentViewDate = scheduler.getState().date;
      const prevDate = scheduler.date.add(currentViewDate, -1, scheduler._mode);
      const nextDate = scheduler.date.add(currentViewDate, 1, scheduler._mode);

      const isPrevInRange = await isDateInRange(prevDate, params.payPeriod.range)
      const isNextInRange = await isDateInRange(nextDate, params.payPeriod.range)

      //if (!isDateInRange(prevDate, params.payPeriod.range)) {
      if (!isPrevInRange) {  
        prevButton.disabled = true;
        prevButton.style.display = 'none'; // or use `block` to show
      } else {
        prevButton.disabled = false;
        prevButton.style.display = 'block'; // or use `block` to show
      }

      //if (!isDateInRange(nextDate, params.payPeriod.range)) {
      if (!isNextInRange) {  
        nextButton.disabled = true;
        nextButton.style.display = 'none'; // or use `block` to show
      } else {
        nextButton.disabled = false;
        nextButton.style.display = 'block'; // or use `block` to show
      }


    }
    
    
  }

  const loadData = async () => {
   
    if (store.get("PayPeriod").id !== store.get("PayPeriodToday").id) {
      store.set("Today", false)
    }
  
  // eslint-disable-next-line
    const shiftGroup = {
      key: 1,
      label: 'Shifts',
      level: 0,
      open: true,
      children: [], 
      $parent: null,

    }

    // eslint-disable-next-line
    var sortedShifts = _.sortBy( shifts, function(o) {
      return moment(o.start)
    },['desc']);
 
    for (let s of shifts) {
       
      const tagGroup = {
        key: s.tags.length > 0 ? s.tags[0].id : '',
        label: s.tags.length > 0 ? s.tags[0].name: '',
        level: 1,
        open: true,
        children: [],
        $parent: 1
      }

      const sup = {
        key: s.sectionId, 
        label: s.supervisor.fullName,
        level: 2, 
        $parent: s.tags.length > 0 ? s.tags[0].id : 1
      }
    
      tagGroup.children.push(sup)
    
      if (scheduler.matrix.timeline.y_unit && scheduler.matrix.timeline.y_unit.length > 0) {
        if (scheduler.matrix.timeline.y_unit[0].children ) {
          if (!scheduler.matrix.timeline.y_unit[0].children.some(el => el.key === tagGroup.key)) {
            scheduler.matrix.timeline.y_unit[0].children.push(tagGroup)
          }
        } 
      } else {
       // if (scheduler.matrix.timeline.y_unit[0].children ) {
          //if (!scheduler.matrix.timeline.y_unit[0].children.some(el => el.key === shiftGroup.key)) {
       //     scheduler.matrix.timeline.y_unit.push(shiftGroup)
         // }
       // }

      }

      if (!scheduler.matrix.timeline.y_unit.some(el => el.key === tagGroup.key)) {
        scheduler.matrix.timeline.y_unit.push(tagGroup)
      } 

     /*  if (sup.key !== '264609' && tagGroup.key === 'qyo5N8M00') {
        if (!scheduler.matrix.timeline.y_unit.some(el => (el.key === sup.key && el.$parent === sup.$parent))) {
          scheduler.matrix.timeline.y_unit.push(sup)
        }
      } */
        
      if (scheduler.matrix.timeline.y_unit && scheduler.matrix.timeline.y_unit.length > 0 ) {
        if (!scheduler.matrix.timeline.y_unit.some(el => el.$parent === sup.$parent)) {
          scheduler.matrix.timeline.y_unit.push(sup)
        } else {
          for (let [i,c] of scheduler.matrix.timeline.y_unit[0].children.entries()) {
            if (c.key === sup.$parent) {
              if (!c.children.some(el => el.key === sup.key)) {
                scheduler.matrix.timeline.y_unit[0].children[i].children.push(sup)
              }
            }
          }
        }
      }
    }
  }

  /* const resetNavButtons = async (prevButton, nextButton, scrollDate) => {
    
    // verify scheduler is initialized
    const currentStateDate = scheduler.getState().date

    const isStateDate = currentStateDate instanceof Date && !isNaN(currentStateDate)

    if (isStateDate) {

      let ppViewDate = new Date(params.payPeriod.start)
      const isPPViewDate = ppViewDate instanceof Date && !isNaN(ppViewDate)

      // if scrollDate passed in we want to use this as the date
      const isScrollDate = scrollDate instanceof Date && !isNaN(scrollDate)
      if (isScrollDate) {
        ppViewDate = scrollDate
      }
     
      if (isPPViewDate && isStateDate) {  

        const prevDate = scheduler.date.add(ppViewDate, -1, scheduler._mode);
        const nextDate = scheduler.date.add(ppViewDate, 1, scheduler._mode);

        const isPrevInRange = await isDateInRange(prevDate, params.payPeriod.range)
        const isNextInRange = await isDateInRange(nextDate, params.payPeriod.range)

        //if (!isDateInRange(prevDate, params.payPeriod.range)) {
        if (!isPrevInRange) {  
          prevButton.disabled = true;
          prevButton.style.display = 'none'; // or use `block` to show
        } else {
          prevButton.disabled = false;
          prevButton.style.display = 'block'; // or use `block` to show
        }

        //if (!isDateInRange(nextDate, params.payPeriod.range)) {
        if (!isNextInRange) {  
          nextButton.disabled = true;
          nextButton.style.display = 'none'; // or use `block` to show
        } else {
          nextButton.disabled = false;
          nextButton.style.display = 'block'; // or use `block` to show
        }

      }

    }
      
  }
 */

  useEffect(() => {
    state.mounted && fetchData()

    // reset buttons on params change
    const prevButton = document.querySelector('.dhx_cal_prev_button')
    const nextButton = document.querySelector('.dhx_cal_next_button')
    const todayButton = document.querySelector('.dhx_cal_today_button')

    if (store.get('Today') === true) {
       todayButton.style.backgroundColor = '#1D90FF'
       todayButton.style.color = 'white'
    } else {
      todayButton.style.backgroundColor = ''
      todayButton.style.color = 'black'
    }
    

    if (state.scale === 14 || state.scale === 28) { 
      nextButton.disabled = true
      nextButton.style.display = 'none'
      prevButton.disabled = true
      prevButton.style.display = 'none'

    } else {

     // resetNavButtons(prevButton, nextButton)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (state.mounted) {
      //console.log("Changed Scale")

      const prevButton = document.querySelector('.dhx_cal_prev_button')
      const nextButton = document.querySelector('.dhx_cal_next_button')

      const configObj = scheduler.matrix

      if (state.scale === 1) {
        /* 
        scheduler.matrix.timeline.x_step = 1
        scheduler.matrix.timeline.column_width = Math.round(state.scale * 0.4)
        scheduler.matrix.timeline.x_unit="hour" // measuring unit of the X-Axis.
        scheduler.matrix.timeline.x_date="%H:%i" // date format of the X-Axis   

        scheduler.matrix.timeline.x_step = 1               // step for the X-Axis
        scheduler.matrix.timeline.x_size = 24           // number of steps (hours in this case)
        //scheduler.matrix.timeline.x_start = 0             // starting position of the X-Axis
        scheduler.matrix.timeline.x_length = 24
        */

        scheduler.xy.scale_height = 40;//sets the height of the X-Axis
        configObj.timeline.x_step = 1
        configObj.timeline.column_width = Math.round(state.scale * 0.4)
        configObj.timeline.x_unit="hour"
        configObj.timeline.x_date="%H:%i"
         
        setSecondScale({
          x_unit: "day",
          x_date: "%D %M %d"
        })
        configObj.timeline.second_scale.x_unit = "day"
        configObj.timeline.second_scale.x_date = "%D %M %d"

        nextButton.disabled = false
        nextButton.style.display = 'block'
        prevButton.disabled = false
        prevButton.style.display = 'block'
      
        setState({...state, step: 1})
        fetchData()
      } else 
        if (state.scale === 14) {
          /*
          scheduler.matrix.timeline.x_step = 14
          scheduler.matrix.timeline.column_width = Math.round(state.scale * 0.6)
          scheduler.matrix.timeline.x_unit="hour" // measuring unit of the X-Axis.
          scheduler.matrix.timeline.x_date="%H:%i" // date format of the X-Axis     

          scheduler.matrix.timeline.x_step = 1               // step for the X-Axis
          scheduler.matrix.timeline.x_size = 24             // number of steps (days in this case)
          //scheduler.matrix.timeline.x_start = 0             // starting position of the X-Axis
          scheduler.matrix.timeline.x_length = 24
          */ 

          scheduler.xy.scale_height = 40;//sets the height of the X-Axis
          configObj.timeline.x_step = 14
          configObj.timeline.column_width = Math.round(state.scale * 0.6)
          configObj.timeline.x_unit="hour"
          configObj.timeline.x_date="%H:%i"

          setSecondScale({
            x_unit: "day",
            x_date: "%D %M %d"
          })
          configObj.timeline.second_scale.x_unit = "day"
          configObj.timeline.second_scale.x_date = "%D %M %d"
  
          nextButton.disabled = true
          nextButton.style.display = 'none'
          prevButton.disabled = true
          prevButton.style.display = 'none'

          setState({...state, step: 29})
          fetchData()
      } else 
        if (state.scale === 28) {
          /* 
          //scheduler.matrix.timeline.x_step = 28
          scheduler.matrix.timeline.x_step = 14
          scheduler.matrix.timeline.column_width = state.scale // Math.round(state.scale * 0.8)
          scheduler.matrix.timeline.x_unit="hour" // measuring unit of the X-Axis.
          scheduler.matrix.timeline.x_date="%H:%i" // date format of the X-Axis    
          */

          // just recreate it
          //const ppToday = store.get("Today")
          //const ppStart = ppToday ? new Date().toString() : store.get("PayPeriod").start
      
          /*
          scheduler.createTimelineView({
            column_width: Math.round(state.scale * 0.4),
            container_autoresize: true,
            event_min_dy: 60,
            folder_dy: 30,
            folder_events_available: true,
            name: "timeline",
            scrollable: true,
            scroll_position: new Date(ppStart),
            section_autoheight: false,
            //show_unassigned: false,
            //smart_rendering: false,
            
            y_unit: [
              { 
                key: 1, 
                label: "Shifts",
                 open: true,
                 children: []
              }
            ],
            y_property: "sectionId",
            render: "tree",
            days: 14,   // for day mode only
            second_scale:{
              x_unit: "day", // unit which should be used for second scale
              x_date: "%m/%d/%y" //"%D %M %d" // date format which should be used for second scale, "July 01"
            },
            //dx: 0,
            dy: 70,
            x_unit: "hour", // measuring unit of the X-Axis.
            x_date: "%H:%i", // date format of the X-Axis
            x_step: state.scale === 1 ? 1: state.scale === 14 ? 14: 29, //state.scale > 30 ? 1 : 29,
            x_length: 24,
            //x_size: 24
            views: [
              {
                type: "day"
              }
            ]
          }) 
          */

          //scheduler.init("scheduler_target", new Date(ppStart), "timeline")

          //

          scheduler.xy.scale_height = 20;//sets the height of the X-Axis
          configObj.timeline.x_step = 30
          configObj.timeline.column_width = state.scale
          configObj.timeline.x_date="%H:%i"
          //configObj.timeline.x_length = 30
          configObj.timeline.x_size = 27

        
         setSecondScale({
            x_unit: "day",
            x_date: "%m/%d/%y" 
          })
          configObj.timeline.second_scale.x_unit = "day"
          configObj.timeline.second_scale.x_date = "%m/%d/%y" 

          
          nextButton.disabled = true
          nextButton.style.display = 'none'
          prevButton.disabled = true
          prevButton.style.display = 'none'
          
          setState({...state, step: 30})
          fetchData()
        }
        scheduler.setCurrentView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.scale])

  useEffect(() => {
    //console.log("useEffect shifts", shifts)
    scheduler.clearAll()
    //scheduler.deleteAllSections()
    
    const ppToday = store.get("Today")
    const ppStart = ppToday ? new Date().toString() : store.get("PayPeriod").start

    if (store.get("PayPeriod").id !== params.payPeriod.id) {
      setParams({...params, payPeriod: store.get("PayPeriod") })


    }
  
  // eslint-disable-next-line
    var sortedShifts = _.sortBy( shifts, function(o) {
      return moment(o.start)
    },['desc']);

    /* var sortedTagShifts = _.sortBy( shifts, function(o) {
      return o.tags[0].name
    },['desc']); */

    var sortedTagShifts = shifts && shifts.length > 0 ? shifts.sort(customSort) : shifts

 
    if (scheduler.matrix.timeline && scheduler.matrix.timeline.y_unit) {
   
      for (let section of scheduler.matrix.timeline.y_unit ) {
        if (section.key !== 1) {
          scheduler.deleteSection(section.key)
        }
      }

      //scheduler.matrix.timeline.scroll_position = sortedShifts.length > 0 ? new Date(sortedShifts[0].start_date) : new Date()
      scheduler.matrix.timeline.scroll_position =  new Date()
      //scheduler.matrix.timeline.column_width=Math.round(state.scale * 0.4)
      scheduler.matrix.timeline.x_step=state.scale === 1 ? 1: state.scale === 14 ? 14: 28

      // reset buttons on params change
      const prevButton = document.querySelector('.dhx_cal_prev_button')
      const nextButton = document.querySelector('.dhx_cal_next_button')
      if (prevButton || nextButton) {
        //resetNavButtons(prevButton, nextButton, scheduler.matrix.timeline.scroll_position)
      }


      //delete scheduler.matrix.timeline.y_unit //[i]
    
     
      //delete scheduler.matrix.timeline.second_scale
      /*
      scheduler.createTimelineView({
        column_width: state.scale * 0.4,
        container_autoresize: true,
        event_min_dy: 60,
        folder_dy: 29,
        folder_events_available: true,
        name: "timeline",
        scrollable: true,
        scroll_position: sortedShifts.length > 0 ? new Date(sortedShifts[0].start_date) : new Date(),
        section_autoheight: false,
        y_unit: [
          { 
            key: 1, 
            label: "Shifts",
              open: true,
              children: []
          }
        ],
        y_property: "sectionId",
        render: "tree",
        second_scale:{
          x_unit: "day", // unit which should be used for second scale
          x_date: "%M %d" // date format which should be used for second scale, "July 01"
        },
        days: 14,
        x_unit: "hour", // measuring unit of the X-Axis.
        x_date: "%H:%i ", // date format of the X-Axis
        x_step:  state.scale === 1 ? 1: state.scale === 14 ? 14: 28, //state.scale > 30 ? 1 : 29,
        x_length: 24,
    
      })
      */
    
       //scheduler.updateView()
      scheduler.attachEvent("onTemplatesReady", setTemplates)
      scheduler.attachEvent("onTemplatesReady", attachEvents)
      scheduler.config.occurrence_timestamp_in_utc = true

      // Setting up the days to show all days, including weekends
      scheduler.ignore_timeline = function(date){
        //console.log('date before load data  -> ', date)
        return false; // Don't ignore any days
      };

      scheduler.init("scheduler_target", new Date(ppStart), "timeline")
    //}

    }
    
    loadData()
  
    //scheduler.parse(shifts, "json")
    scheduler.parse(sortedTagShifts, "json")
  
    setState({ ...state, mounted: true })

    const mode = scheduler.getState().mode
    if (mode === 'timeline') scheduler.updateView()
    
    scheduler.openAllSections();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts])

  useEffect(() => {
    setState({ ...state, mounted: true })
    const mode = scheduler.getState().mode
    scheduler.config.start_on_monday = false
    // scheduler.config.api_date = "%m-%d-%Y %H:%i"
    scheduler.config.date_format = "%m-%d-%Y %H:%i"
    const ppStart = store.get("PayPeriodToday").start

    if (mode === undefined) {
      // THIS WAS CAUSING THE GROWING HEADER HEIGHTS   
      scheduler.createTimelineView({
        column_width: Math.round(state.scale * 0.4),
        container_autoresize: true,
        event_min_dy: 60,
        folder_dy: 30,
        folder_events_available: true,
        name: "timeline",
        scrollable: true,
        scroll_position: new Date(ppStart),
        section_autoheight: false,
        //show_unassigned: false,
        //smart_rendering: false,
        
        y_unit: [
          { 
            key: 1, 
            label: "Shifts",
             open: true,
             children: []
          }
        ],
        y_property: "sectionId",
        render: "tree",
        days: 14,   // for day mode only
        second_scale: secondScale,
        /* second_scale:{
          x_unit:  "day", // unit which should be used for second scale
          x_date: "%D %M %d" // date format which should be used for second scale, "July 01"
        }, */
        //dx: 0,
        dy: 70,
        x_unit: "hour", // measuring unit of the X-Axis.
        x_date: "%H:%i", // date format of the X-Axis
        x_step: state.scale === 1 ? 1: state.scale === 14 ? 14: 29, //state.scale > 30 ? 1 : 29,
        x_length: 24,
        //x_size: 24
        views: [
          {
            type: "day"
          }
        ]
      }) 
      
    }

    //const date = new Date(sortedShifts[0].start_date)
    scheduler.attachEvent("onTemplatesReady", setTemplates)
    scheduler.attachEvent("onTemplatesReady", attachEvents)
    scheduler.config.occurrence_timestamp_in_utc = true;

    // Setting up the days to show all days, including weekends
    scheduler.ignore_timeline = function(date){
      //console.log(`ignore_timeline date before Emitter -> ${date.getDay()} : `, date)
      return true //false; // Don't ignore any days
    };

    scheduler.init("scheduler_target", new Date(ppStart), "timeline")
    setState({ ...state, mounted: true })
    fetchData()

    Emitter.on("ShiftDrawerUpdate", () => {
      //console.log("Emitter.on('ShiftDrawerUpdate', ()", query)
      fetchData()
    })
    Emitter.on("ShiftDrawerClose", () => {
      //console.log("Emitter.on('ShiftDrawerClose', ()", query)
   //  fetchData()
    })


    // start new stuff for buttons
    const prevButton = document.querySelector('.dhx_cal_prev_button');
    const nextButton = document.querySelector('.dhx_cal_next_button');

    if (!prevButton || !nextButton) {
      console.error('Prev or Next button not found');
      return;
    }

    const checkPrevNextButtons = async () => {
      const currentViewDate = scheduler.getState().date;
      const prevDate = scheduler.date.add(currentViewDate, -1, scheduler._mode);
      const nextDate = scheduler.date.add(currentViewDate, 1, scheduler._mode);


      const isPrevInRange = await isDateInRange(prevDate, params.payPeriod.range)
      const isNextInRange = await isDateInRange(nextDate, params.payPeriod.range)

      //if (!isDateInRange(prevDate, params.payPeriod.range)) {
      if (!isPrevInRange) {  
        prevButton.disabled = true;
        prevButton.style.display = 'none'; // or use `block` to show
      } else {
        prevButton.disabled = false;
        prevButton.style.display = 'block'; // or use `block` to show
      }

      //if (!isDateInRange(nextDate, params.payPeriod.range)) {
      if (!isNextInRange) {  
        nextButton.disabled = true;
        nextButton.style.display = 'none'; // or use `block` to show
      } else {
        nextButton.disabled = false;
        nextButton.style.display = 'block'; // or use `block` to show
      }

      // check today button
      
      const todayButton = document.querySelector('.dhx_cal_today_button')
      if (todayButton) {
        store.set('Today', false)
        todayButton.style.backgroundColor = ''
        todayButton.style.color = 'black'
      }
    };

    // Initial check
    checkPrevNextButtons();

    // Attach event listeners to the buttons
    prevButton.addEventListener('click', () => {
      setTimeout(checkPrevNextButtons, 0);
    });

    nextButton.addEventListener('click', () => {
      setTimeout(checkPrevNextButtons, 0);
    });

    // Cleanup event listeners on component unmount
    return () => {
      prevButton.removeEventListener('click', () => {
        setTimeout(checkPrevNextButtons, 0);
      });

      nextButton.removeEventListener('click', () => {
        setTimeout(checkPrevNextButtons, 0);
      });
    };


    // end new stuff for buttons


    //resetPayPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const changeScale = direction => {
    console.log("changeSCALE", direction)
    
    let newScale = state.scale

    if (direction === "in" ) {
      if (state.scale === 28) {
        newScale = 14
        store.set("Today", false)
      } else if (newScale === 14) {
        newScale = 1
        store.set("Today", true)
      } else {
        newScale = 1
        store.set("Today", false)
      }

    } 
    if (direction === "out") {
      if (state.scale === 1) {
        newScale = 14
      } else {
        newScale = 28
      } 

      store.set("Today", false)
    }

    setState({ ...state, scale: newScale, update: false })
  }
  

  return (
    <div className="ScheduleTimeline">
      <div className="view_title_schedule">
        <Row style={{ width: "100%"}}>
          <Col span={4} className="PageTitle">
            <h1 data-cy="page-title">Schedule</h1>
          </Col>
          <Col span={6} className="GroupSelector">
              <TagSelect allowClear={true} value={params.tagIds} record={params} field="tagIds" handleChange={selectGroup} />
          </Col>
          <Col span={4} className="SupervisorSelector">
            <SupervisorSelect isMulti={false} isClearable={true} supervisor={state.supervisor} handleChange={selectSupervisor} />
          </Col>
          <Col span={2} >&nbsp;&nbsp;
            <Button onClick={handleClear}>Clear All</Button>
          </Col>
          <Col span={2} >
            <Button onClick={fetchData}>Refresh</Button>
          </Col>
          <Col span={5}>
            <small>
              <dl>
                <dt className="pto"></dt>
                <dd>Leave</dd>
                <dt className="ot"></dt>
                <dd>Overtime</dd>
                <dt className="sick"></dt>
                <dd>Sick</dd>
                <dt className="regular"></dt>
                <dd>Regular</dd>
            </dl>
            </small>
          
          </Col>
        </Row>
      </div>
      <div id="scheduler_target" className="dhx_cal_container" style={{ width: "100%", height: "100%" }}>
        <div className="dhx_cal_navline">
          <Row>
            <Col span={4}>
              <div className="payPeriodSelector">
              <h5>Pay Period: <b>{moment(params.payPeriod.start).format('YYYY-MM-DD')} - {moment(params.payPeriod.end).format('YYYY-MM-DD')}</b></h5>
                <PayPeriodNav payPeriod={params.payPeriod} onClick={fetchData} toggleLoading={toggleLoading} />
              </div>
              <div className="dhx_cal_tab" name="day_tab" style={{ right: "204px" }} />
              <div className="dhx_cal_tab" name="week_tab" style={{ right: "140px" }} />
              <div className="dhx_cal_tab" name="timeline_tab" style={{ right: "280px" }} />
              <div className="dhx_cal_tab" name="month_tab" style={{ right: "76px" }} />
            </Col>
            <Col span={9}>
              <div className="dhx_cal_date timelineDate" />
            </Col>
            <Col span={4} className="timelineZoom">
              <Button.Group>
                <Button data-cy="dhtmlx-zoom-in" icon="zoom-in" onClick={() => changeScale("in")} />
                <Button data-cy="dhtmlx-zoom-out" icon="zoom-out" onClick={() => changeScale("out")} />
              </Button.Group>
              {/* <Slider className="time_scale" min={20} max={100} onChange={changeScale} value={state.scale} step={10} /> */}
            </Col>
            <Col span={7}>

              <div data-cy="dhx_minical_icon" className="dhx_minical_icon" id="dhx_minical_icon"  onClick={showMinical}>
                &nbsp;
              </div>
              
              <Tooltip title='Pervious Day'>
                <div data-cy="dhtmlx-prev-button" className="dhx_cal_prev_button">
                  &nbsp;
                </div>
              </Tooltip>
             
              <Tooltip title='Return to Today'>
                <div data-cy="dhtmlx-today-button" className="dhx_cal_today_button"  onClick={resetPayPeriod}>
                  &nbsp;
                </div>
              </Tooltip>
             
              <Tooltip title='Next Day'>
                <div data-cy="dhtmlx-next-button" className="dhx_cal_next_button">
                  &nbsp;
                </div>
              </Tooltip>

            </Col>
          </Row>
        </div>
        <div className="dhx_cal_header" />
        <div className="dhx_cal_data" />
      </div>
      {!!shiftDrawerId && <ShiftDrawer />}
    </div>
  )
}

export default ScheduleTimeline
